import springApi from '../../services/springApi'

export const getCallLogs = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/call_logs', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCallLog = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/call_logs', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCallLog = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/call_logs/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)
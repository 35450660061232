import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import CampaignsList from './CampaignsList'

export default function Campaigns() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/campaigns" component={CampaignsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}
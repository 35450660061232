import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import InvoicesTable from './partials/InvoicesTable'
import CustomFiltersDrawer from './partials/CustomFiltersDrawer'
import ListTopBar from '../../components/ListTopBar'

import { getInvoices } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function InvoicesList() {
	const user = useSelector(state => state.auth.user)
	const [invoices, setInvoices] = useState(null)
	const [filterType, setFilterType] = useState('reference')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [filters, setFilters] = useState({
		'filter[products.description]': ''
	})
	const [showFiltersDrawer, setShowFiltersDrawer] = useState(false)

	useEffect(() => {
		!invoices && getInvoices({
			[`filter[${filterType}]`]: filterValue,
			'filter[store_code]': user.current_store_code,
			sort: '-sold_at',
			include: 'products,customer.callLogs,customer.saleLogs,customer.invoices.products',
			...pagination,
			...filters
		})
			.then(res => {
				setInvoices(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ invoices, filterType, filterValue, pagination, user, filters ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title={`Facturas Tienda ${user.current_store_code}`}
				subtitle="Momentos Spring"
				reload={() => setInvoices(null)}
				options={[
					{ label: 'Buscar por referencia factura', value: 'reference' },
					{ label: 'Buscar por nombre de producto', value: 'products.description' },
					{ label: 'Buscar por nombre del cliente', value: 'customer.name' },
					{ label: 'Buscar por cédula del cliente', value: 'customer.document' },
				]}
				ctaText="Filtros Avanzados"
				handleCtaClick={() => setShowFiltersDrawer(true)}
			/>
			<Card>
				<CardBody>
					<InvoicesTable
						invoices={invoices}
						reload={() => setInvoices(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showFiltersDrawer && (
				<CustomFiltersDrawer 
					visible
					onClose={() => setShowFiltersDrawer(false)}
					reload={() => setInvoices(null)}
					filters={filters}
					setFilters={setFilters}
				/>
			)}
		</React.Fragment>
	)
}
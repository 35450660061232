import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Divider, Drawer } from 'antd'
import moment from 'moment'
import 'moment/locale/es'

import { getCustomer } from '../services'
import { handleError } from '../../../helpers'

export default function CustomerInvoicesDrawer({ visible, onClose, customerId }) {
	const [customer, setCustomer] = useState(null)

	useEffect(() => {
		getCustomer(customerId, {
			include: 'invoices.products'
		})
			.then(res => setCustomer(res.data.data))
			.catch(error => handleError(error))
	}, [ customerId ])

	return (
		<React.Fragment>
			<Drawer visible={visible} onClose={onClose} title="Listado de Facturas" width={500}>
				{ customer ? (
					<React.Fragment>
						<p className='bold'>Facturas de {customer.name.toUpperCase()}</p>
						{ customer.invoices.map( invoice => (
							<div key={invoice.id}>
								<Divider />
								<h6>Factura del {moment(invoice.sold_at).format('DD-MMM-YYYY').toUpperCase()}</h6>
								{ invoice.products.map(product => (
									<p key={product.id} className='mb-1'>
										{product.description} ({product.pivot.quantity} UND)
									</p>
								)) }
								<p className='bold'>Facturado: {moment(invoice.sold_at).fromNow()}</p>
							</div>
						)) }
					</React.Fragment>
				) : (
					<Spinner animation='border' size="sm" />
				)}
			</Drawer>
		</React.Fragment>
	)
}
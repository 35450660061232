import springApi from '../../services/springApi'

export const getCustomers = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/customers', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getCustomer = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/customers/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCustomer = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/customers', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCustomer = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/customers/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)
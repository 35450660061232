import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { Divider, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faDollarSign, faFileInvoiceDollar, faPhone } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment/locale/es'

import Pagination from '../../../components/Pagination'
import CustomerInvoicesDrawer from './CustomerInvoicesDrawer'
import RegisterSaleLogModal from '../../SaleLogs/RegisterSaleLogModal'
import RegisterCallLogModal from '../../CallLog/RegisterCallLogModal'

export default function CustomersTable({ customers, reload, pagination, setPagination }) {
	const [invoicesDrawer, setInvoicesDrawer] = useState(null)
	const [registerSaleLog, setRegisterSaleLog] = useState(null)
	const [registerCallLog, setRegisterCallLog] = useState(null)
	
	const columns = [
		{
			title: 'Miembro Desde',
			dataIndex: 'member_at',
			render: t => moment(t).format('DD-MM-YYYY')
		},
		{
			title: 'Nombre',
			dataIndex: 'name',
			render: t => t ? t.toUpperCase() : <em>Sin registro</em>
		},
		// {
		// 	title: 'Total Facturas',
		// 	dataIndex: 'invoices',
		// 	render: t => `${t.length} facturas`
		// },
		// {
		// 	title: 'Última Compra',
		// 	dataIndex: 'last_invoice_at',
		// 	render: t => `${moment(t).format('DD-MM-YYYY')} (${moment(t).fromNow()})`
		// },
		{
			title: 'Llamadas Registradas',
			width: '110px',
			dataIndex: 'call_logs_count',
			render: t => `${t} llamadas`
		},
		{
			title: 'Recompras Registradas',
			width: '110px',
			dataIndex: 'sale_logs_count',
			render: t => `${t} recompras`
		},
		{
			title: 'Acciones',
			width: '200px',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Realizar Llamada">
						<Button 
							size='sm' 
							color={r.is_callable ? 'primary' : 'dark'} 
							disabled={!r.is_callable}
							outline 
							onClick={() => setRegisterCallLog(r)}
						>
							<FontAwesomeIcon icon={faPhone} />
						</Button>
					</Tooltip>
					<Divider type='vertical' />
					{ ((r.mobile && r.mobile.toString().length === 10) || (r.phone && r.phone.toString().length === 10)) && (
						<React.Fragment>
							<Tooltip title="Whatsapp">
								<Button 
									size='sm' 
									color={r.is_callable ? 'primary' : 'dark'} 
									disabled={!r.is_callable}
									outline 
									onClick={() => window.open(`https://wa.me/57${(r.mobile && r.mobile.toString().length === 10) ? r.mobile : r.phone}?text=`)}
								>
									<FontAwesomeIcon icon={faComments} />
								</Button>
							</Tooltip>
							<Divider type='vertical' />
						</React.Fragment>
					)}
					<Tooltip title="Registrar Venta">
						<Button size='sm' color="primary" outline className='px-2' onClick={() => setRegisterSaleLog(r)}>
							<FontAwesomeIcon icon={faDollarSign} />
						</Button>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Ver Facturas">
						<Button size='sm' color="primary" outline className='px-2' onClick={() => setInvoicesDrawer(r.id)}>
							<FontAwesomeIcon icon={faFileInvoiceDollar} />
						</Button>
					</Tooltip>
				</React.Fragment>
			)
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={customers}
				columns={columns}
				rowKey={r => r.id}
				loading={!customers}
				pagination={false}
				size="small"
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ registerSaleLog && (
				<RegisterSaleLogModal 
					visible
					onClose={() => setRegisterSaleLog(null)}
					customer={registerSaleLog}
					reload={reload}
				/>
			)}
			{ registerCallLog && (
				<RegisterCallLogModal 
					visible
					onClose={() => setRegisterCallLog(null)}
					customer={registerCallLog}
					reload={reload}
				/>
			)}
			{ invoicesDrawer && (
				<CustomerInvoicesDrawer 
					visible
					onClose={() => setInvoicesDrawer(null)}
					customerId={invoicesDrawer}
				/>
			)}
		</React.Fragment>
	)
}
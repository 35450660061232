import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import SegmentsList from './SegmentsList'

export default function Segments() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/segments" component={SegmentsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}
import React from 'react'
import { Drawer } from 'antd'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'

export default function CustomFiltersDrawer({ visible, onClose, filters, setFilters, reload }) {

	console.log(filters)

	const handleChange = (e, term) => {
		e.target.checked ? 
			setFilters({ 
				...filters, 
				'filter[products.description]': `${term},${filters['filter[products.description]']}`
			})
		: (
			setFilters({ 
				...filters,
				'filter[products.description]': filters['filter[products.description]'].replace(`${term},`,'')
			})
		)
	}

	const keywords = {
		colchones: 'COLCHON,COL+,COLCH+,COLC+,K L,K Z,K ATLANTIC,K SPECIAL DREAM,K PREMIUM WEB,K EXTREME WEB,COLCH,TOO FUN,TEMPUR,K HEALTHY,K REST,COMBO EXPRESSION,COL ORX,COL L1,COMB COL',
		almohadas: 'ALMOHADA,ALM,SET ALM',
		protectores: 'PROTECTOR,PROT,PRO IMP'
	}
	
	return (
		<React.Fragment>
			<Drawer 
				visible={visible}
				onClose={onClose}
				title="Filtros Avanzados"
				width="350px"
			>
				<Form.Group>
					<Form.Label>Categorias compradas por el cliente</Form.Label>
					<Form.Check 
						type="checkbox"
						label="Colchones"
						checked={filters['filter[products.description]'].includes('COLCHON') ? true : false}
						onChange={e => handleChange(e, keywords.colchones)}
					/>
					<Form.Check 
						type="checkbox"
						label="Almohadas"
						checked={filters['filter[products.description]'].includes('ALMOHADA')}
						onChange={e => handleChange(e, keywords.almohadas)}
					/>
					<Form.Check 
						type="checkbox"
						label="Protectores"
						checked={filters['filter[products.description]'].includes('PROTECTOR')}
						onChange={e => handleChange(e, keywords.protectores)}
					/>
				</Form.Group>
				<Button 
					color="primary" 
					onClick={() => {
						reload()
						onClose()
					}}
					className="mt-2"
				>
					Aplicar Filtros
				</Button>
			</Drawer>
		</React.Fragment>
	)
}
import { message } from 'antd'
import React, { useState } from 'react'
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { handleError } from '../../../helpers'
import { updateCustomerData } from '../services'

export default function ContactUpdateForm({ customer, prevStep, nextStep, source, agentDocument, setEmail }) {
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors } } = useForm()

	const onSubmit = values => {
		setLoading(true)

		updateCustomerData({
			customer_id: customer.id,
			email: values.email.toLowerCase(),
			birthday: `${values.birthday_month}-${values.birthday_day}`,
			source,
			agent_document: agentDocument
		})
			.then(res => {				
				if(res.data.warn_message){
					message.warning(res.data.warn_message, 7)
					setLoading(false)
				} else {
					message.success(res.data.message)
					setEmail(values.email)
					nextStep()
				}
			})
			.catch(error => {
				handleError(error)
				setLoading(false)
			})
	}

	return (
		<React.Fragment>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<div style={{ textAlign:'left', maxWidth:'350px', margin: 'auto' }}>
					<Form.Group>
						<Form.Label>Día y mes de cumpleaños</Form.Label>
						<InputGroup>
							<Form.Control
								as="select"
								{...register('birthday_day', { required:true })}
								defaultValue={(customer && customer.birthday) ? customer.birthday.slice(-2) : ''}
								style={{ maxWidth: '70px' }}
							>
								<option value="">:: Día ::</option>
								<option value="01">1</option>
								<option value="02">2</option>
								<option value="03">3</option>
								<option value="04">4</option>
								<option value="05">5</option>
								<option value="06">6</option>
								<option value="07">7</option>
								<option value="08">8</option>
								<option value="09">9</option>
								<option value="10">10</option>
								<option value="11">11</option>
								<option value="12">12</option>
								<option value="13">13</option>
								<option value="14">14</option>
								<option value="15">15</option>
								<option value="16">16</option>
								<option value="17">17</option>
								<option value="18">18</option>
								<option value="19">19</option>
								<option value="20">20</option>
								<option value="21">21</option>
								<option value="22">22</option>
								<option value="23">23</option>
								<option value="24">24</option>
								<option value="25">25</option>
								<option value="26">26</option>
								<option value="27">27</option>
								<option value="28">28</option>
								<option value="29">29</option>
								<option value="30">30</option>
								<option value="31">31</option>
							</Form.Control>
							<Form.Control
								as="select"
								{...register('birthday_month', { required:true })}
								defaultValue={(customer && customer.birthday) ? customer.birthday.slice(0, 2) : ''}
							>
								<option value="">:: Mes de cumpleaños ::</option>
								<option value="01">Enero</option>
								<option value="02">Febrero</option>
								<option value="03">Marzo</option>
								<option value="04">Abril</option>
								<option value="05">Mayo</option>
								<option value="06">Junio</option>
								<option value="07">Julio</option>
								<option value="08">Agosto</option>
								<option value="09">Septiembre</option>
								<option value="10">Octubre</option>
								<option value="11">Noviembre</option>
								<option value="12">Diciembre</option>
							</Form.Control>
						</InputGroup>
						<Form.Text className='text-muted'>Cada año recibirás tu bono de cumpleaños</Form.Text>
						{ errors.birthday_month && <Form.Text className='text-warning'>este campo es requerido</Form.Text> }
						{ errors.birthday_day && <Form.Text className='text-warning'>este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Correo Electrónico Personal</Form.Label>
						<Form.Control 
							type="email"
							{...register('email', { required: true })}
							placeholder="Escribe tu correo personal"
						/>
						<Form.Text className='text-muted'>Enviaremos a este correo tu premio</Form.Text>
						{ errors.email && <Form.Text className='text-warning'>Ingresa un correo válido</Form.Text> }
					</Form.Group>
				</div>
				<div className='mb-3'>
					<p className='small mb-3 px-4'>Al reclamar tu premio estas aceptando nuestra pólitica de tratamiento de datos que puedes consultar <a href="https://colchonesspring.vteximg.com.br/arquivos/Politica-de-Tratamiento-de-Datos-Spring-V3.pdf?v=637896721233970000" target="_blank" rel="noreferrer">aquí.</a></p>
					<Button color="primary" type="submit" disabled={loading}>
						{ loading && <Spinner animation='border' size="sm" className='mr-1' /> }
						Reclamar Mi Premio
					</Button>
				</div>
				<Button variant='outline-primary' onClick={prevStep}>
					No quiero participar
				</Button>
			</Form>
		</React.Fragment>
	)
}
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment/locale/es'

import Pagination from '../../../components/Pagination'
import EditCampaignModal from './EditCampaignModal'

import { deleteCampaign } from '../services'
import { handleError } from '../../../helpers'

export default function CampaignsTable({ campaigns, reload, pagination, setPagination }) {
	const { ranking } = useSelector(state => state.auth.user)
	const [editCampaign, setEditCampaign] = useState(null)

	const handleDelete = id => {
		deleteCampaign(id)	
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'Fecha Creación',
			width: '150px',
			dataIndex: 'created_at',
			render: t => moment(t).format('DD-MM-YYYY')
		},
		{
			title: 'Nombre',
			dataIndex: 'name',
			render: t => t.toUpperCase()
		},
		{
			title: 'Descripción',
			dataIndex: 'description'
		},
		{
			title: 'Estado',
			dataIndex: 'is_active',
			render: t => t ? 'Activa' : 'Inactiva'
		},
		{
			title: 'Acciones',
			width: '180px',
			render: (t,r) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<Button 
							size='sm' 
							color="primary"
							outline
							disabled={ranking !== 'admin'}
							onClick={() => setEditCampaign(r)}
						>
							<FontAwesomeIcon icon={faEdit} />
						</Button>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción no se puede revertir"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger: true }}
							onConfirm={() => handleDelete(r.id)}
						>
							<Button
								size="sm"
								color="primary"
								outline
								disabled={ranking !== 'admin'}
							>
								<FontAwesomeIcon icon={faTrash} />
							</Button>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = record => (
		<React.Fragment>
			<p><strong>Descripción: </strong>{record.description}</p>
			<p className='mb-0'><strong>Incentivos: </strong>{record.incentives}</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={campaigns}
				columns={columns}
				rowKey={r => r.id}
				loading={!campaigns}
				pagination={false}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: <Empty description="No tiene campañas creadas" /> }}
			/>
			<Pagination 
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ editCampaign && (
				<EditCampaignModal 
					visible
					onClose={() => setEditCampaign(null)}
					campaign={editCampaign}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}
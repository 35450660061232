import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import SaleLogsList from './SaleLogsList'

export default function SaleLogs() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/sale_logs" component={SaleLogsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}
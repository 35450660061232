import React from 'react'
import moment from 'moment'

export default function SegmentsSelect({ setSegmentFilters, reload }) {
	const handleChange = e => {
		switch (e.target.value) {
			case 'birthdays':
				setSegmentFilters({ 'filter[birthday]': moment().format('MM') })
				break;

			case 'resell_protectores':
				setSegmentFilters({ 'filter[invoices.products.description]': 'PROT' })
				break;

			case 'resell_almohadas':
				setSegmentFilters({ 'filter[invoices.products.description]': 'ALM' })
				break;
		
			default:
				setSegmentFilters({})
				break;
		}

		reload()
	}

	return (
		<React.Fragment>
			<div className='mb-2'>
				<select className="form-control" onChange={handleChange}>
					<option value="">:: Todos los clientes ::</option>
					<option value="birthdays">Cumpleaños del Mes</option>
					<option value="resell_protectores">Clientes de protectores</option>
					<option value="resell_almohadas">Clientes de almohadas</option>
				</select>
			</div>
		</React.Fragment>
	)
}
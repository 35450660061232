import springApi from '../../services/springApi'

export const getCampaigns = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/campaigns', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCampaign = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/campaigns', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCampaign = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/campaigns/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCampaign = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/campaigns/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import CustomersList from './CustomersList'

export default function Customers() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/customers" component={CustomersList} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}
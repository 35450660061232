import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import SaleLogsTable from './partials/SaleLogsTable'
import ListTopBar from '../../components/ListTopBar'

import { getSaleLogs } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function SaleLogsList() {
	const user = useSelector(state => state.auth.user)
	const [saleLogs, setSaleLogs] = useState(null)
	const [filterType, setFilterType] = useState('invoice')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!saleLogs && getSaleLogs({
			[`filter[${filterType}]`]: filterValue,
			'filter[agent_doc]': user.document,
			sort: '-created_at',
			...pagination,
		})
			.then(res => {
				setSaleLogs(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ saleLogs, filterType, filterValue, pagination, user ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title={`Recompras Asesor C.C. ${user.document}`}
				subtitle="Recompra Fiel Momentos Spring"
				reload={() => setSaleLogs(null)}
				options={[
					{ label: 'Buscar por número de factura', value: 'invoice' },
					{ label: 'Buscar por cédula del cliente', value: 'client_doc' },
				]}
			/>
			<Card>
				<CardBody>
					<SaleLogsTable
						saleLogs={saleLogs}
						reload={() => setSaleLogs(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}
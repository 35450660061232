import springApi from '../../services/springApi'

export const generalReport = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/reports/general', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeDownloadToken = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/download_tokens', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import GeneralReport from './GeneralReport'

export default function Reports() {
	return (
		<React.Fragment>
			<Switch>
				<Redirect exact path="/reports" to="/reports/general" />

				<Route exact path="/reports/general" component={GeneralReport} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}
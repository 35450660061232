import React, { useEffect, useState } from 'react'
import { Card, CardBody } from 'reactstrap'

import CampaignsTable from './partials/CampaignsTable'
import NewCampaignModal from './partials/NewCampaignModal'
import ListTopBar from '../../components/ListTopBar'

import { getCampaigns } from './services'
import { handleError, parsePagination } from '../../helpers'
import { Form } from 'react-bootstrap'

export default function CampaignsList() {
	const [campaigns, setCampaigns] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewModal, setShowNewModal] = useState(false)
	const [onlyActive, setOnlyActive] = useState(true)

	useEffect(() => {
		!campaigns && getCampaigns({
			[`filter[${filterType}]`]: filterValue,
			'filter[active]': onlyActive ? 1 : null,
			...pagination,
		})
			.then(res => {
				setCampaigns(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ campaigns, filterType, filterValue, pagination, onlyActive ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title={`Campañas Recompra Fiel Momentos`}
				subtitle="Momentos Spring"
				reload={() => setCampaigns(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
				]}
				ctaText="Crear Campaña"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<Form.Check
						label="Mostrar solo campañas activas" 
						checked={onlyActive}
						onChange={() => {
							setOnlyActive(!onlyActive)
							setCampaigns(null)
						}}
						className='block mb-2'
					/>
					<CampaignsTable
						campaigns={campaigns}
						reload={() => setCampaigns(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewModal && (
				<NewCampaignModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={() => setCampaigns(null)}
				/>
			)}
		</React.Fragment>
	)
}
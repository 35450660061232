import React from 'react'
import { Button, Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'

export default function ReportTopBar({ title, subtitle, handleExport }) {	
	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className='float-right'>
						<Button color="primary" onClick={handleExport}>
							Exportar Recompras
						</Button>
					</div>
					<CardTitle>{title}</CardTitle>
					<CardSubtitle>{subtitle}</CardSubtitle>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}
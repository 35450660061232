import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PublicLayout from '../../layouts/PublicLayout'

import CustomerForm from './CustomerForm'
import AgentForm from './AgentForm'
import StoreValidation from './StoreValidation'
import { registerLinkClick } from './services'

export default function CustomerData() {

	useEffect(() => {
		registerLinkClick({ url: window.location.href })
			.catch(error => console.error(error))
	}, [])
	

	return (
		<PublicLayout>
			<Switch>
				<Route exact path="/actualizar_datos" component={CustomerForm} />
				<Route exact path="/actualizar_datos/qr_validar" component={StoreValidation} />
				<Route exact path="/completar_mis_datos" component={CustomerForm} />
				<Route exact path="/completar_datos_asesor" component={AgentForm} />

				<Redirect to="/404" />
			</Switch>
		</PublicLayout>
	)
}
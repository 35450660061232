import { message } from 'antd'
import React, { useState } from 'react'
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap'

import { eligibilityByDocument } from '../services'
import { handleError } from '../../../helpers'

export default function SearchByDocument({ 
	nextStep, setCustomer, source, agentDocument, setAgentDocument 
}) {
	const [document, setDocument] = useState('')
	const [loading, setLoading] = useState(false)

	const onSubmit = () => {
		if(!document) 
			return message.warning('Debe ingresar un documento válido')

		if(setAgentDocument && !agentDocument)
			return message.warning('Debe ingresar la cédula del asesor Spring')

		setLoading(true)

		eligibilityByDocument({ 
			document, 
			source, 
			agent_document: agentDocument
		})
			.then(res => {
				if(res.data.data){
					setCustomer(res.data.data)
					message.success(res.data.message)
						.then(() => nextStep())
				} else {
					message.info(res.data.message)
					setLoading(false)
				}
			})
			.catch(error => {
				handleError(error)
				setLoading(false)
			})
	}

	return (
		<React.Fragment>
			{ setAgentDocument && (
				<Form.Group>
					<Form.Label>Cédula del Asesor Spring</Form.Label>
					<Form.Control 
						value={agentDocument}
						onChange={e => setAgentDocument(e.target.value)}
						type="number"
						placeholder="Cédula del Asesor"
					/>
				</Form.Group>
			)}
			{ setAgentDocument && (
				<Form.Label>Cédula Cliente Miembro Momentos Spring</Form.Label>
			)}
			<InputGroup>
				<Form.Control 
					type="number"
					value={document}
					onChange={e => setDocument(e.target.value)}
					placeholder="Escribe aquí..."
				/>
				<Button type="primary" onClick={onSubmit} disabled={loading}>
					{loading && <Spinner animation='border' size='sm' className='mr-1' />}
					Consultar
				</Button>
			</InputGroup>
		</React.Fragment>
	)
}
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Card, CardBody, Col, Row } from 'reactstrap'
import { Empty, Result, message } from 'antd'
import moment from 'moment'

import ReportTopBar from './partials/ReportTopBar'
import GeneralReportContent from './partials/GeneralReportContent'
import GeneralReportForm from './partials/GeneralReportForm'

import { generalReport } from './services'
import { handleError } from '../../helpers'
import ExportResellsModal from './partials/ExportResellsModal'

export default function GeneralReport() {
	const user = useSelector(state => state.auth.user)
	const [report, setReport] = useState(null)
	const [loading, setLoading] = useState(false)
	const [showExportModal, setShowExportModal] = useState(false)
	const [params, setParams] = useState({
		year: moment().format('YYYY').toString(),
		month: moment().format('MM').toString(),
	})

	const onSubmit = () => {
		if(!params.year || !params.month) 
			return message.warning('Debe ingresar un año y mes')

		setLoading(true)
		generalReport(params)
			.then(res => setReport(res.data.data))
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	if(user.role !== 'admin') 
		return <Result status="403" title="No tiene acceso a este reporte" />

	return (
		<React.Fragment>
			<ReportTopBar 
				title="Reporte General Recompra Fiel Momentos"
				subtitle="Reporte de resultados registrados en la plataforma"
				handleExport={() => setShowExportModal(true)}
			/>
			<Row>
				<Col lg="3" md="4">
					<GeneralReportForm 
						report={report}
						params={params}
						setParams={setParams}
						onSubmit={onSubmit}
						loading={loading}
					/>
					<Alert color='warning' className='mt-2'>A partir del 1 Julio 2023 solo se mostrara dentro de este reporte recompras confirmadas. Para meses previos a Julio 2023 no se tomará en cuenta este filtro.</Alert>
				</Col>
				<Col>
					<Card>
						<CardBody>
							{ report ? (
								<GeneralReportContent 
									report={report}
									loading={loading}
								/>
							) : (
								<Empty description="Aún no ha generado el reporte" />
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
			{ showExportModal && (
				<ExportResellsModal 
					visible
					onClose={() => setShowExportModal(false)}
					defaultParams={params}
				/>
			)}
		</React.Fragment>
	)
}
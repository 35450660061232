import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { message } from 'antd'
import styled from 'styled-components'

import { sendRewardSelected } from '../services'
import { handleError } from '../../../helpers'

export default function PickAndSendReward({ customer, resetForm, setCurrentStep, email }) {
	// const [rewardSelected, setRewardSelected] = useState('')
	const rewardSelected = 'almohada_2x1'
	const [loading, setLoading] = useState(false)

	const onSubmit = () => {
		if(!rewardSelected)
			return message.warn('Debes seleccionar un premio primero')

		setLoading(true)

		sendRewardSelected({ 
			customer_id: customer.id, 
			reward_template: rewardSelected 
		})
			.then(res => {
				message.info(res.data.message, 7)
					.then(() => resetForm())
			})
			.catch(error => {
				handleError(error)
				setLoading(false)
			})
	}

	return (
		<React.Fragment>
			{/* <RewardContainer 
				active={rewardSelected === 'manual_del_sueno'}
				onClick={() => setRewardSelected('manual_del_sueno')}
			>
				<img src="https://smart4cdn.s3.amazonaws.com/spring/landings/banner_manual.jpg" alt="Manual de Sueño" width="100%" />
			</RewardContainer>
			<RewardContainer 
				active={rewardSelected === 'taller_del_sueno'}
				onClick={() => setRewardSelected('taller_del_sueno')}
			>
				<img src="https://smart4cdn.s3.amazonaws.com/spring/landings/banner_taller.jpg" alt="Taller de Sueño" width="100%" />
			</RewardContainer>
			<RewardContainer 
				active={rewardSelected === 'cuentos_para_dormir'}
				onClick={() => setRewardSelected('cuentos_para_dormir')}
			>
				<img src="https://smart4cdn.s3.amazonaws.com/spring/landings/banner_cuentos.jpg" alt="Cuentos para Dormir" width="100%" />
			</RewardContainer> */}
			<RewardContainer 
				active={rewardSelected === 'almohada_2x1'}
			>
				<div className='text-center'>
					<img src="https://smart4cdn.s3.amazonaws.com/spring/landings/banner_almohada.jpg" alt="Almohada 2x1" width="70%" className='mb-1' />
					<h5 className='text-primary mb-3'>ALMOHADA MEMORY SENSE 2X1</h5>
				</div>
			</RewardContainer>
			<Button variant="primary" disabled={loading} onClick={onSubmit}>
				{ loading && <Spinner animation='border' size="sm" className='mr-1' /> }
				¡Solicitar Premio!
			</Button>
			<p className='mt-3 mb-1'>El premio será enviado a: <b>{email}</b></p>
			<Button variant='outline-primary' onClick={() => setCurrentStep(1)}>Cambiar email</Button>
			<div className='text-center mt-3'>
				<p className='small text-muted'>Promoción válida para las personas que actualicen sus datos para el programa de clientes "Momentos" de Industrias Spring S.A.S. Promoción válida para la referencia de almohadas Memory Sense. El cliente comprará una almohada Memory Sense y se llevará otra sin costo adicional. Solo aplica para tiendas físicas de Spring. 1000 Unidades disponibles. Válido de 10 de julio de  2023 al 31 de diciembre de 2024. Solo se podrá redimir una almohada por cliente.</p>
			</div>
		</React.Fragment>
	)
}

const RewardContainer = styled.div`
	border: ${props => props.active ? '4px solid #e42537' : '2px solid #f8f8f8'};
	margin-bottom: 10px;
	&:hover {
		border: ${props => props.active ? '4px solid #e42537' : '2px solid #e42537'};
		cursor: pointer;
		color: #e42537;
		background-color: #f8f8f8;
		opacity: 0.7;
	}
`
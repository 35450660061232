import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import CustomersTable from './partials/CustomersTable'
import ListTopBar from '../../components/ListTopBar'

import { getCustomers } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function CustomersList() {
	const user = useSelector(state => state.auth.user)
	const [customers, setCustomers] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 10, total: 0})

	useEffect(() => {
		!customers && getCustomers({
			[`filter[${filterType}]`]: filterValue,
			'filter[active_in_store]': user.current_store_code,
			'filter[is_member]': 1,
			sort: '-member_at',
			include: 'callLogsCount,saleLogsCount',
			...pagination,
		})
			.then(res => {
				setCustomers(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ customers, filterType, filterValue, pagination, user ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title={`Directorio Clientes Tienda ${user.current_store_code}`}
				subtitle="Momentos Spring"
				reload={() => setCustomers(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por cédula', value: 'document' },
					{ label: 'Buscar por número de factura', value: 'invoices.reference' },
					{ label: 'Buscar por producto comprado', value: 'invoices.products.description' },
				]}
			/>
			<Card>
				<CardBody>
					<CustomersTable
						customers={customers}
						reload={() => setCustomers(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}
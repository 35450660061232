import React from 'react'
import styled from 'styled-components'

import LogoSpring from '../assets/images/logo_spring.png'

export default function PublicLayout({ children }) {
	return (
		<Container>
			<img src={LogoSpring} alt="Logo Colchones Spring" width="170px" />
			<FormContainer>
				{children}
			</FormContainer>
			<p>Desarrollado por{" "}
				<a href="//smart4.com.co" target="_blank" rel="noreferrer">
					Smart4 Solutions
				</a>
				{" | "}Versión {process.env.REACT_APP_VERSION}
			</p>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: #f7f8f9;	
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-top: 30px;
`

const FormContainer = styled.div`
	background-color: #fff;
	padding: 35px;	
	max-width: 580px;
	width: 100%;
	text-align: center;
	margin: 20px auto;
`
import axios from "axios"

/*
 * Instancia con el servicio principal del backend
 */
const springCrmApi = () => {

   var url = window.location.host.split('.')

   let domain = (url[0].includes('localhost')) ? 'spring_crm.test' : 'admin.spring.com.co'

   return axios.create({
      baseURL: `https://${domain}/api`,
      timeout: 20000,
      headers: {
         "Content-Type": "application/json; charset=UTF-8",
      }
   });
};

export default springCrmApi;

import { Result } from 'antd'
import React from 'react'

export default function StoreValidation() {
	return (
		<React.Fragment>
			<Result status="success" title="Premio válido" subTitle="Favor proceder a otorgar el 2x1 en almohadas Memory Sense" />
			<div className='text-center mt-3'>
				<p className='text-muted'>Promoción válida para las personas que actualicen sus datos para el programa de clientes "Momentos" de Industrias Spring S.A.S. Promoción válida para la referencia de almohadas Memory Sense. El cliente comprará una almohada Memory Sense y se llevará otra sin costo adicional. Solo aplica para tiendas físicas de Spring. 1000 Unidades disponibles. Válido de 10 de julio de  2023 al 30 de noviembre de 2023. Solo se podrá redimir una almohada por cliente.</p>
			</div>
		</React.Fragment>
	)
}
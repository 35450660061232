import React, { useState } from 'react'

import ContactUpdateForm from './partials/ContactUpdateForm'
import PickAndSendReward from './partials/PickAndSendReward'
import SearchByDocument from './partials/SearchByDocument'

export default function AgentForm() {
	const [currentStep, setCurrentStep] = useState(0)
	const [customer, setCustomer] = useState(null)
	const [agentDocument, setAgentDocument] = useState('')
	const [email, setEmail] = useState(null)
	const source = 'customer_form';

	const steps = [
		{ 	
			title: '1. Cédula de asesor y de cliente',
			description: 'Consulta si el cliente es elegible para este programa',
			render: (
				<SearchByDocument 
					source={source}
					setCustomer={setCustomer}
					agentDocument={agentDocument}
					setAgentDocument={setAgentDocument}
					nextStep={() => setCurrentStep(1)} 
				/>
			),
		},
		{
			title: '2. Completa los datos del cliente',
			description: 'Recuerde al cliente que al correo recibirá la recompenza',
			render: (
				<ContactUpdateForm 
					source={source}
					customer={customer}
					agentDocument={agentDocument}
					setEmail={setEmail}
					nextStep={() => setCurrentStep(2)}
					prevStep={() => {
						setCustomer(null)
						setCurrentStep(0)
					}}
				/>
			)
		},
		{
			title: '3. Elija el premio que el cliente desea',
			description: 'Informe cuál incentivo o premio desea el cliente obtener',
			render: (
				<PickAndSendReward 
					customer={customer}
					setCurrentStep={setCurrentStep}
					email={email}
					resetForm={() => {
						setCustomer(null)
						setCurrentStep(0)
					}}
				/>
			)
		}
	]

	const step = steps[currentStep]

	return (
		<React.Fragment>
			<h5 className='mb-0'>{step.title}</h5>
			<p>{step.description}</p>
			<div>
				{ step.render }
			</div>
		</React.Fragment>
	)
}
import springApi from '../../services/springApi'

export const getInvoices = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/invoices', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeInvoice = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/invoices', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateInvoice = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/invoices/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)
import React, { useState } from 'react'

import ContactUpdateForm from './partials/ContactUpdateForm'
import PickAndSendReward from './partials/PickAndSendReward'
import SearchByDocument from './partials/SearchByDocument'

export default function CustomerForm() {
	const [currentStep, setCurrentStep] = useState(0)
	const [customer, setCustomer] = useState(null)
	const [email, setEmail] = useState(null)
	const source = window.location.href.includes('source') ? window.location.href.split('source=')[1] : 'customer_form';

	const steps = [
		{ 	
			title: '1. Ingresa tu cédula',
			description: 'Consulta sí eres elegible para este programa',
			render: (
				<SearchByDocument 
					source={source}
					setCustomer={setCustomer}
					nextStep={() => setCurrentStep(1)} 
				/>
			),
		},
		{
			title: '2. Completa tus datos',
			description: 'Recuerda que en cualquier momento podrás cancelar tu suscripción',
			render: (
				<ContactUpdateForm 
					source={source}
					customer={customer}
					setEmail={setEmail}
					nextStep={() => setCurrentStep(2)}
					prevStep={() => {
						setCustomer(null)
						setCurrentStep(0)
					}}
				/>
			)
		},
		{
			title: '3. Redime tu premio',
			description: `Spring premia tu fidelidad, recibe tu premio al correo que nos indicaste. Recuerda revisar la bandeja de promociones o spam.`,
			render: (
				<PickAndSendReward 
					customer={customer}
					setCurrentStep={setCurrentStep}
					email={email}
					resetForm={() => {
						setCustomer(null)
						setCurrentStep(0)
					}}
				/>
			)
		}
	]

	const step = steps[currentStep]

	return (
		<React.Fragment>
			<h5 className='mb-0'>{step.title}</h5>
			<p>{step.description}</p>
			<div>
				{ step.render }
			</div>
			<div className='text-center mt-3'>
				<p className='small text-muted'>Sus datos serán tratados según nuestra política de tratamiento de datos que puede consultar <a href="https://colchonesspring.vteximg.com.br/arquivos/politica-de-tratamiento-de-datos-spring-v-4.pdf?v=638173374098900000" target='_blank' rel="noreferrer">aquí.</a> Al participar esta aceptando nuestros términos y condiciones que puede consultar <a href="https://colchonesspring.vteximg.com.br/arquivos/TERMINOS-Y-CONDICIONES-PAGINA-WEB.pdf?v=638155579277600000" target='_blank' rel="noreferrer">en este link</a>.</p>
			</div>
		</React.Fragment>
	)
}
import React from 'react'
import { Empty, Table } from 'antd'
import moment from 'moment'

export default function GeneralReportContent({ report, loading }) {

	const columns = [
		{
			title: 'Nombre Asesor',
			dataIndex: 'agent_name',
			filters: report.data.detail_table.map(r => ({ text: r.agent_name, value: r.agent_name })),
			filterMode: 'tree',
			filterSearch: true,
			onFilter: (value, record) => record.agent_name.startsWith(value),
		},
		{
			title: 'Cédula Asesor',
			dataIndex: 'agent_doc',
			filters: report.data.detail_table.map(r => ({ text: r.agent_doc, value: r.agent_doc })),
			filterMode: 'tree',
			filterSearch: true,
			onFilter: (value, record) => record.agent_doc.startsWith(value),
		},
		{
			title: 'Ventas Registradas',
			dataIndex: 'total_sales',
			render: t => `${t} ventas`
		},
		{
			title: 'Primer registro',
			dataIndex: 'first_sales_log',
			render: t => moment(t).format('YYYY-MM-DD')
		},
		{
			title: 'Último registro',
			dataIndex: 'last_sales_log',
			render: t => moment(t).format('YYYY-MM-DD')
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={report.data.detail_table}
				columns={columns}
				rowKey={r => r.agent_doc}
				loading={loading}
				locale={{ emptyText: <Empty description="No se encontraron asesores con ventas" /> }}
			/>
		</React.Fragment>
	)
}
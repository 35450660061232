import springApi from '../../services/springApi'

export const getSaleLogs = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/sale_logs', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeSaleLog = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/sale_logs', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSaleLog = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/sale_logs/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)